html {
  font-size: 62.5%;
}

body {
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  margin: 0;
  font-size: 16px;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
  font-weight: 500;
}

h1 {
  font-size: 40px;
  font-size: 4rem;
  display: inline-block;
  margin: 1.6rem 0;
}
@media screen and (max-width: 600px) {
  h1 {
    margin-top: 0;
  }
}

h2 {
  clear: both;
  font-size: 32px;
  font-size: 3.2rem;
  margin-top: 4rem;
  padding-top: 4rem;
}
.page-about h2, .page-contribute h2, .page-events h2, .page-features h2, .page-home h2, .page-presskit h2, .page-resources h2, .page-api h2 {
  border-top: 0;
  margin-top: 2rem;
  padding-top: 0;
}

h3 {
  font-size: 24px;
  font-size: 2.4rem;
  margin-top: 3rem;
  clear: both;
}

h4 {
  font-size: 20px;
  font-size: 2rem;
  margin-top: 3rem;
  clear: both;
}

h5 {
  font-size: 18px;
  font-size: 1.8rem;
  margin-top: 2rem;
  clear: both;
}

h6 {
  font-size: 16px;
  font-size: 1.6rem;
  margin-top: 2rem;
  clear: both;
}

h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-size: inherit;
}
@media screen and (max-width: 600px) {
  h2,
h3,
h4,
h5,
h6 {
    margin: 8px 0;
  }
}

.mat-tab-body-wrapper h2 {
  margin-top: 0;
}

p,
ol,
ul,
li,
input,
a {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 32px;
  line-height: 3.2rem;
  font-family: inherit;
  font-weight: 400;
}
p > em,
ol > em,
ul > em,
li > em,
input > em,
a > em {
  letter-spacing: 0.3px;
  letter-spacing: 0.03rem;
}

p {
  margin: 1em 0;
}

p + ul {
  margin-top: 4px;
}

ol li,
ol p {
  margin: 4px 0;
}

ol ol {
  list-style-type: lower-alpha;
}
ol ol ol {
  list-style-type: lower-roman;
}

li {
  padding-bottom: 8px;
  line-height: 24px;
  line-height: 2.4rem;
}
li p {
  margin: 0;
}
li a {
  line-height: inherit;
}

a {
  text-decoration: none;
}

.app-toolbar a {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-transform: uppercase;
}

strong {
  font-weight: 500;
}

table {
  border-collapse: collapse;
  border-radius: 2px;
  border-spacing: 0;
  margin: 12px 0 32px;
}

table tbody th {
  max-width: 100px;
  padding: 13px 32px;
  text-align: left;
}

td {
  font-weight: 400;
  padding: 8px 30px;
}
td > p:first-child,
td ul:first-child {
  margin-top: 0;
}
td > p:last-child,
td ul:last-child {
  margin-bottom: 0;
}

th {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 13px 32px;
  text-align: left;
}

code {
  font-family: "Roboto Mono", monospace;
  font-size: 90%;
}

.sidenav-content a:hover {
  text-decoration: underline;
}

.folder-api aio-doc-viewer :not(.github-links) > a[href^="http:"], .folder-api aio-doc-viewer :not(.github-links) > a[href^="https:"], .folder-cli aio-doc-viewer :not(.github-links) > a[href^="http:"], .folder-cli aio-doc-viewer :not(.github-links) > a[href^="https:"], .folder-docs aio-doc-viewer :not(.github-links) > a[href^="http:"], .folder-docs aio-doc-viewer :not(.github-links) > a[href^="https:"], .folder-errors aio-doc-viewer :not(.github-links) > a[href^="http:"], .folder-errors aio-doc-viewer :not(.github-links) > a[href^="https:"], .folder-extended-diagnostics aio-doc-viewer :not(.github-links) > a[href^="http:"], .folder-extended-diagnostics aio-doc-viewer :not(.github-links) > a[href^="https:"], .folder-guide aio-doc-viewer :not(.github-links) > a[href^="http:"], .folder-guide aio-doc-viewer :not(.github-links) > a[href^="https:"], .folder-start aio-doc-viewer :not(.github-links) > a[href^="http:"], .folder-start aio-doc-viewer :not(.github-links) > a[href^="https:"], .folder-tutorial aio-doc-viewer :not(.github-links) > a[href^="http:"], .folder-tutorial aio-doc-viewer :not(.github-links) > a[href^="https:"] {
  display: inline-flex;
  align-items: center;
}
.folder-api aio-doc-viewer :not(.github-links) > a[href^="http:"]::after, .folder-api aio-doc-viewer :not(.github-links) > a[href^="https:"]::after, .folder-cli aio-doc-viewer :not(.github-links) > a[href^="http:"]::after, .folder-cli aio-doc-viewer :not(.github-links) > a[href^="https:"]::after, .folder-docs aio-doc-viewer :not(.github-links) > a[href^="http:"]::after, .folder-docs aio-doc-viewer :not(.github-links) > a[href^="https:"]::after, .folder-errors aio-doc-viewer :not(.github-links) > a[href^="http:"]::after, .folder-errors aio-doc-viewer :not(.github-links) > a[href^="https:"]::after, .folder-extended-diagnostics aio-doc-viewer :not(.github-links) > a[href^="http:"]::after, .folder-extended-diagnostics aio-doc-viewer :not(.github-links) > a[href^="https:"]::after, .folder-guide aio-doc-viewer :not(.github-links) > a[href^="http:"]::after, .folder-guide aio-doc-viewer :not(.github-links) > a[href^="https:"]::after, .folder-start aio-doc-viewer :not(.github-links) > a[href^="http:"]::after, .folder-start aio-doc-viewer :not(.github-links) > a[href^="https:"]::after, .folder-tutorial aio-doc-viewer :not(.github-links) > a[href^="http:"]::after, .folder-tutorial aio-doc-viewer :not(.github-links) > a[href^="https:"]::after {
  content: "\e89e";
  font-family: "Material Icons";
  margin-left: 0.3rem;
  float: right;
  display: flex;
  align-items: center;
}

/* ==============================
   BASE STYLES
   ============================== */
.sidenav-container {
  width: 100%;
  height: 100vh;
}

.sidenav-content {
  min-height: 100vh;
  padding: 80px 3rem 2rem;
  max-width: 50em;
  margin: 0 auto;
}
@media screen and (max-width: 600px) {
  .sidenav-content {
    min-height: 450px;
    padding: 80px 2rem 1rem;
  }
}
@media screen and (max-width: 480px) {
  .sidenav-content {
    padding: 80px 1rem 1rem;
  }
}
.page-about .sidenav-content, .page-contribute .sidenav-content, .page-events .sidenav-content, .page-features .sidenav-content, .page-home .sidenav-content, .page-presskit .sidenav-content, .page-resources .sidenav-content, .page-api .sidenav-content, .page-file-not-found .sidenav-content, .page-guide-cheatsheet .sidenav-content {
  max-width: none;
}

.sidenav-content button {
  min-width: 24px;
}

@media (max-width: 600px) {
  aio-menu {
    display: none;
  }
}

.no-animations aio-doc-viewer > * {
  transition: none !important;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

footer {
  position: relative;
  line-height: 24px;
  line-height: 2.4rem;
  flex: 1;
  padding: 48px 25px;
  z-index: 0;
  font-weight: 300;
}
footer aio-footer {
  display: block;
  position: relative;
  z-index: 0;
  overflow: auto;
}
footer aio-footer > * {
  max-width: 50em;
}
footer aio-footer .footer-block {
  margin: 0 0.3rem;
}
footer aio-footer a {
  text-decoration: none;
  z-index: 20;
  position: relative;
}
footer aio-footer a:hover {
  text-decoration: underline;
}
footer aio-footer a:visited {
  text-decoration: none;
}
footer aio-footer a:focus {
  outline: 1px solid rgba(255, 255, 255, 0.8);
  outline-offset: 2px;
}
footer aio-footer .footer-block-heading {
  font-size: 16px;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 400;
  margin: 8px 0 12px;
}
@media (max-width: 600px) {
  footer aio-footer .footer-block-heading {
    font-size: 14px;
    font-size: 1.4rem;
  }
}
footer aio-footer p {
  text-align: center;
  margin: 10px auto 5px;
}
@media (max-width: 480px) {
  footer aio-footer p {
    text-align: left;
  }
}
footer aio-footer div.grid-fluid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin: 0 auto 40px;
  justify-content: center;
}
footer aio-footer div.grid-fluid ul {
  list-style-position: inside;
  padding: 0;
  margin: 0;
}
footer aio-footer div.grid-fluid ul li {
  list-style-type: none;
  padding: 4px 0;
  text-align: left;
}
@media (max-width: 45rem) {
  footer aio-footer div.grid-fluid {
    grid-template-columns: repeat(2, calc(50% - (24px/2)));
  }
  footer aio-footer div.grid-fluid .footer-block {
    margin: 1.5rem 2.4rem 0.8rem 20%;
  }
}
@media (max-width: 25rem) {
  footer aio-footer div.grid-fluid .footer-block {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
}
@media (max-width: 20rem) {
  footer aio-footer div.grid-fluid {
    grid-template-columns: 1fr;
  }
}
footer::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("/assets/images/logos/angular/angular_whiteTransparent_withMargin.png") top 0 left 0 repeat, url("/assets/images/logos/angular/angular_whiteTransparent_withMargin.png") top 80px left 160px repeat;
  opacity: 0.05;
  background-size: 320px auto;
}

html, body {
  height: 100%;
}

.center-layout {
  margin: 0 auto;
  max-width: 62.5em;
}

.center-layout-wide {
  margin: 0 auto;
  max-width: 84em;
}

.github-links + .content h1 {
  max-width: 90%;
}

.clear {
  clear: both;
}

.l-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.center {
  text-align: center;
}

.visually-hidden {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  min-height: 480px;
  height: 80vh;
  max-height: 560px;
  box-sizing: border-box;
  padding: 48px 48px 32px 48px;
  overflow: hidden;
  transform: skewY(8deg);
  transform-origin: 100%;
}
@media (max-width: 480px) {
  .hero {
    max-height: 486px;
    padding-top: 40px;
    transform: none;
  }
}
.hero .hero-title {
  display: inline-block;
  font-size: 28px;
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 48px;
  line-height: 4.8rem;
  margin: 0 8px 0 0;
  text-transform: uppercase;
}
.hero .hero-title.is-standard-case {
  text-transform: none;
}

section#intro {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 900px;
  width: 100%;
  height: 480px;
  margin: 0 auto;
  padding: 48px 0 0;
}
section#intro .hero-logo {
  background-image: url(/assets/images/logos/angular/angular.svg);
}
@media (max-width: 65rem) {
  section#intro {
    flex-direction: column;
    justify-content: center;
    padding: 40px 0 32px;
  }
  section#intro button {
    margin: 0;
    height: 60px;
  }
}
section#intro .homepage-container {
  width: 50%;
  max-width: 1040px;
  margin: 0 auto;
  margin-top: -7%;
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 65rem) {
  section#intro .homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}
section#intro .hero-headline {
  font-size: 40px;
  font-size: 4rem;
  line-height: 64px;
  line-height: 6.4rem;
  font-weight: 500;
  margin: 32px 0;
  color: inherit;
}
section#intro .hero-headline:after {
  display: none;
}
@media (max-width: 65rem) {
  section#intro .hero-headline {
    text-align: center;
  }
}
@media (max-width: 575px) {
  section#intro .hero-headline {
    font-size: 32px;
    font-size: 3.2rem;
    line-height: 50px;
    line-height: 5rem;
  }
}
section#intro .hero-logo {
  display: flex;
  width: 50%;
  min-width: 250px;
  height: 400px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 8px;
}

.announcement-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  max-width: 50vw;
  margin: 0 auto;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  box-sizing: border-box;
  transition: all 0.3s ease-in;
}
@media (max-width: 991px) {
  .announcement-bar {
    flex-direction: column;
    text-align: center;
    padding: 32px 16px;
  }
}
@media (max-width: 768px) {
  .announcement-bar {
    width: 100%;
    max-width: none;
  }
}
.announcement-bar > * {
  margin: 8px;
}
.announcement-bar .button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-width: 160px;
  font-size: 16px;
  font-size: 1.6rem;
  border-radius: 48px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  box-sizing: border-box;
  cursor: pointer;
}
.announcement-bar .material-icons {
  display: none;
  right: 0;
  position: static;
  transition: all 0.3s ease-in;
  font-size: 16px;
  font-size: 1.6rem;
}
.announcement-bar p {
  font-size: 16px;
  font-size: 1.6rem;
  margin: 8px;
  text-align: center;
}

.home-row .card {
  width: 70%;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  box-sizing: border-box;
  transition: box-shadow 0.5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  min-width: 350px;
  height: auto;
  margin: auto;
  padding: 24px;
  box-shadow: 0 6px 6px rgba(10, 16, 20, 0.15), 0 0 52px rgba(10, 16, 20, 0.12);
}
.home-row .card:hover {
  box-shadow: 0 8px 8px rgba(10, 16, 20, 0.24), 0 0 8px rgba(10, 16, 20, 0.12);
  text-decoration: none;
}
@media (max-width: 600px) {
  .home-row .card {
    margin: 16px auto 0;
  }
  .home-row .card h2 {
    margin: 0;
  }
  .home-row .card img {
    max-width: none;
    height: 70px;
  }
}
@media (max-width: 1300px) {
  .home-row .card img {
    height: 70px;
    max-width: none;
  }
}
.home-row .card img {
  margin: 16px;
}
.home-row .card .card-text-container {
  margin: 0 16px;
}
.home-row .card .card-text-container p {
  text-align: left;
  margin: 0;
  padding: 8px 0;
}

.button.hero-cta {
  padding: 2px 34px 0;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 40px;
  line-height: 4rem;
  border-radius: 48px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  box-sizing: border-box;
  cursor: pointer;
}
.button.hero-cta:hover {
  opacity: 0.9;
}

.page-about .sidenav-content, .page-contribute .sidenav-content, .page-events .sidenav-content, .page-features .sidenav-content, .page-home .sidenav-content, .page-presskit .sidenav-content, .page-resources .sidenav-content {
  padding: 0 0 3rem;
}
.page-about article, .page-contribute article, .page-events article, .page-features article, .page-home article, .page-presskit article, .page-resources article {
  padding: 3rem;
  padding-top: 0;
}
@media (max-width: 800px) {
  .page-about article, .page-contribute article, .page-events article, .page-features article, .page-home article, .page-presskit article, .page-resources article {
    padding: 2.2rem;
    padding-top: 0;
  }
}

.page-home .sidenav-content {
  padding-bottom: 0;
}

.cta-bar.announcement-bar {
  background: none;
  box-shadow: none;
}

.text-headline {
  font-size: 20px;
  font-size: 2rem;
  font-weight: 500;
  margin-top: 10px;
  text-transform: uppercase;
}

aio-shell:not(.view-SideNav) mat-sidenav-container.sidenav-container {
  max-width: none;
}

div[layout=row] {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
@media (max-width: 480px) {
  div[layout=row] {
    display: block;
  }
}

.layout-row {
  flex-direction: row;
}

.home-rows {
  overflow: hidden;
}
@media (max-width: 600px) {
  .home-rows {
    margin: 0;
  }
}

.background-superhero-paper {
  background-size: 100%;
  background-blend-mode: multiply;
}

.home-row {
  max-width: 920px;
  margin: 32px auto;
}
.home-row .promo-img-container, .home-row .text-container {
  max-width: 50%;
}
@media (max-width: 480px) {
  .home-row .promo-img-container, .home-row .text-container {
    max-width: 100%;
    text-align: center;
  }
  .home-row .promo-img-container:nth-child(even), .home-row .text-container:nth-child(even) {
    flex-direction: column-reverse;
  }
}
.home-row .text-block {
  padding-right: 15%;
}
@media (max-width: 600px) {
  .home-row .text-block {
    padding: 0;
  }
}
.home-row .promo-img-container p {
  margin: 0 20px;
}
.home-row .promo-img-container img {
  max-width: 90% !important;
}

.marketing-banner {
  margin-top: 64px;
  padding: 32px;
}
@media (max-width: 600px) {
  .marketing-banner {
    margin-top: 56px;
    padding: 18px;
  }
}
.marketing-banner .banner-headline {
  text-transform: uppercase;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 300;
  margin: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}
@media (max-width: 600px) {
  .marketing-banner .banner-headline {
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 400;
  }
}
.marketing-banner .banner-headline:after {
  display: none;
}
.page-features .marketing-banner {
  margin-bottom: 20px;
}

.nf-container {
  align-items: center;
  padding: 32px;
}

.nf-response {
  margin: 32px;
  height: 100%;
  flex-direction: column;
}
.nf-response h1 {
  font-size: 48px;
  font-size: 4.8rem;
  text-transform: uppercase;
  margin: 8px 0;
}

.nf-icon.material-icons {
  font-size: 100px;
  font-size: 10rem;
  position: static;
}

.no-animations.mat-drawer-transition .mat-drawer-content {
  transition: none;
}

mat-sidenav-container.sidenav-container {
  min-height: 100%;
  height: auto !important;
  margin: 0;
  transform: none;
}
mat-sidenav-container.sidenav-container.has-floating-toc .sidenav-content-container {
  padding-right: 18vw;
}
mat-sidenav-container.sidenav-container .sidenav-content-container {
  height: auto;
}
mat-sidenav-container.sidenav-container .sidenav-content-container .sidenav-content {
  overflow-x: auto;
}
mat-sidenav-container.sidenav-container mat-sidenav.sidenav {
  position: fixed;
  top: 64px;
  bottom: 0;
  left: 0;
  min-width: 260px;
}
@media (max-width: 599px) {
  mat-sidenav-container.sidenav-container mat-sidenav.sidenav {
    top: 56px;
  }
}

aio-nav-menu {
  display: block;
  margin: 0 2px;
  max-width: 268px;
}
aio-nav-menu:first-of-type {
  margin-top: 16px;
}
aio-nav-menu:last-of-type {
  margin-bottom: 16px;
}
aio-nav-menu ul, aio-nav-menu a {
  margin: 0;
}
aio-nav-menu aio-nav-item .vertical-menu-item {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-wrap: break-word;
  padding: 8px;
  text-decoration: none;
  text-align: left;
  width: 100%;
  word-wrap: break-word;
}
aio-nav-menu aio-nav-item .vertical-menu-item:focus {
  outline: #1E88E5 auto 2px;
}
aio-nav-menu aio-nav-item .vertical-menu-item span {
  padding-right: 32px;
}
aio-nav-menu aio-nav-item .vertical-menu-item .mat-icon {
  flex: 0 0 2.4rem;
  display: flex;
  align-items: center;
}
aio-nav-menu aio-nav-item a.vertical-menu-item[href^="http:"] .vertical-menu-item-text, aio-nav-menu aio-nav-item a.vertical-menu-item[href^="https:"] .vertical-menu-item-text {
  display: inline-flex;
  align-items: center;
}
aio-nav-menu aio-nav-item a.vertical-menu-item[href^="http:"] .vertical-menu-item-text::after, aio-nav-menu aio-nav-item a.vertical-menu-item[href^="https:"] .vertical-menu-item-text::after {
  content: "\e89e";
  font-family: "Material Icons";
  margin-left: 0.3rem;
  float: right;
  display: flex;
  align-items: center;
}
aio-nav-menu aio-nav-item button.vertical-menu-item {
  border: none;
  background-color: transparent;
  margin: 0;
  width: 100%;
  overflow: hidden;
}
aio-nav-menu aio-nav-item .heading-children.expanded {
  visibility: visible;
  opacity: 1;
  padding-left: 0;
  max-height: 4000px;
  transition: visibility 500ms, opacity 500ms, max-height 500ms;
  transition-timing-function: ease-in-out;
}
aio-nav-menu aio-nav-item .heading-children.collapsed {
  overflow: hidden;
  visibility: hidden;
  padding-left: 0;
  opacity: 0;
  max-height: 1px;
  transition: visibility 275ms, opacity 275ms, max-height 280ms;
  transition-timing-function: ease-out;
}
.no-animations aio-nav-menu aio-nav-item .heading-children.expanded, .no-animations aio-nav-menu aio-nav-item .heading-children.collapsed {
  transition: none !important;
}
aio-nav-menu aio-nav-item .level-1 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 28px;
  line-height: 2.8rem;
  font-weight: 400;
  padding-left: 20px;
  margin: 0;
  transition: background-color 0.2s;
}
aio-nav-menu aio-nav-item .level-2 {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 24px;
  line-height: 2.4rem;
  font-weight: 400;
  margin: 0;
  padding-left: 36px;
}
aio-nav-menu aio-nav-item .level-3 {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 24px;
  line-height: 2.4rem;
  margin: 0;
  padding-left: 44px;
}
aio-nav-menu aio-nav-item .level-4 {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 24px;
  line-height: 2.4rem;
  margin: 0;
  padding-left: 52px;
}
aio-nav-menu aio-nav-item .level-1.collapsed > .mat-icon, aio-nav-menu aio-nav-item .level-2.collapsed > .mat-icon, aio-nav-menu aio-nav-item .level-3.collapsed > .mat-icon {
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}
aio-nav-menu aio-nav-item .level-1.expanded > .mat-icon, aio-nav-menu aio-nav-item .level-2.expanded > .mat-icon, aio-nav-menu aio-nav-item .level-3.expanded > .mat-icon {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}

mat-toolbar.app-toolbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}
@media (min-width: 481px) {
  .page-home mat-toolbar.app-toolbar:not(.transitioning) {
    background-color: transparent;
    box-shadow: none;
    position: absolute;
    transition: background-color 0.2s linear;
  }
}
@media (min-width: 1150px) {
  .folder-api mat-toolbar.app-toolbar .hamburger, .folder-cli mat-toolbar.app-toolbar .hamburger, .folder-docs mat-toolbar.app-toolbar .hamburger, .folder-errors mat-toolbar.app-toolbar .hamburger, .folder-extended-diagnostics mat-toolbar.app-toolbar .hamburger, .folder-guide mat-toolbar.app-toolbar .hamburger, .folder-start mat-toolbar.app-toolbar .hamburger, .folder-tutorial mat-toolbar.app-toolbar .hamburger {
    margin: 0 8px 0 0;
    visibility: visible;
  }
}

mat-toolbar.app-toolbar mat-toolbar-row {
  padding: 0 16px 0 0;
}
mat-toolbar.app-toolbar .hamburger {
  height: 100%;
  margin: 0 8px 0 0;
  padding: 0;
}
@media (min-width: 1150px) {
  mat-toolbar.app-toolbar .hamburger {
    margin: 0 16px 0 -64px;
    visibility: hidden;
  }
}
@media (max-width: 480px) {
  mat-toolbar.app-toolbar .hamburger {
    min-width: 15%;
  }
}
mat-toolbar.app-toolbar .hamburger:not(.no-animations) {
  transition-duration: 0.4s;
  transition-property: color, margin;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}
mat-toolbar.app-toolbar .hamburger .mat-icon {
  position: inherit;
}
mat-toolbar.app-toolbar .nav-link.home {
  cursor: pointer;
  margin: 0 16px 0 0;
  padding: 8px 0;
}
mat-toolbar.app-toolbar .nav-link.home:focus {
  outline-offset: 4px;
}
@media screen and (max-width: 1149px) {
  mat-toolbar.app-toolbar .nav-link.home {
    padding: 4px 0;
  }
}
@media screen and (max-width: 480px) {
  mat-toolbar.app-toolbar .nav-link.home {
    margin-right: 8px;
  }
}
mat-toolbar.app-toolbar .nav-link.home img {
  position: relative;
  margin-top: -21px;
  top: 12px;
  height: 40px;
}
@media (max-width: 1149px) {
  mat-toolbar.app-toolbar .nav-link.home img:hover {
    transform: scale(1.1);
  }
}
mat-toolbar.app-toolbar aio-top-menu ul {
  display: flex;
  padding: 0px;
  margin: 0px;
}
mat-toolbar.app-toolbar aio-top-menu ul li {
  display: flex;
  padding-bottom: 2px;
  cursor: pointer;
}
mat-toolbar.app-toolbar aio-top-menu ul li:focus {
  outline: none;
}
mat-toolbar.app-toolbar aio-top-menu ul li a.nav-link {
  margin: 0 4px;
  padding: 0px;
  cursor: pointer;
  display: flex;
}
mat-toolbar.app-toolbar aio-top-menu ul li a.nav-link .nav-link-inner {
  border-radius: 4px;
  font-size: 1.3rem;
  font-size: clamp(10px, 1.6rem, 20px);
  padding: 8px 16px;
  padding: 8px clamp(5px, 0.7vw, 16px);
}
mat-toolbar.app-toolbar aio-top-menu ul li a.nav-link[href^="http:"] .nav-link-inner, mat-toolbar.app-toolbar aio-top-menu ul li a.nav-link[href^="https:"] .nav-link-inner {
  display: inline-flex;
  align-items: center;
}
mat-toolbar.app-toolbar aio-top-menu ul li a.nav-link[href^="http:"] .nav-link-inner::after, mat-toolbar.app-toolbar aio-top-menu ul li a.nav-link[href^="https:"] .nav-link-inner::after {
  content: "\e89e";
  font-family: "Material Icons";
  margin-left: 0.3rem;
  float: right;
  display: flex;
  align-items: center;
}
mat-toolbar.app-toolbar aio-top-menu ul li a.nav-link:focus {
  outline: none;
}
mat-toolbar.app-toolbar aio-top-menu ul li a.nav-link:focus .nav-link-inner {
  border-radius: 1px;
}
mat-toolbar.app-toolbar aio-search-box.search-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-width: 150px;
  height: 100%;
  margin-right: 16px;
  position: relative;
}
mat-toolbar.app-toolbar aio-search-box.search-container input {
  border: none;
  border-radius: 100px;
  padding: 5px 3rem 5px 16px;
  margin-left: 8px;
  width: 180px;
  max-width: 240px;
  line-height: normal;
  height: 50%;
  -webkit-appearance: none;
}
mat-toolbar.app-toolbar aio-search-box.search-container input:focus {
  outline: none;
}
mat-toolbar.app-toolbar aio-search-box.search-container input:-ms-input-placeholder {
  font-size: 14px;
  font-size: 1.4rem;
}
mat-toolbar.app-toolbar aio-search-box.search-container input::-ms-input-placeholder {
  font-size: 14px;
  font-size: 1.4rem;
}
mat-toolbar.app-toolbar aio-search-box.search-container input::-webkit-input-placeholder {
  font-size: 14px;
  font-size: 1.4rem;
}
mat-toolbar.app-toolbar aio-search-box.search-container input::placeholder {
  font-size: 14px;
  font-size: 1.4rem;
}
@media (min-width: 1000px) {
  mat-toolbar.app-toolbar aio-search-box.search-container input {
    transition: width 0.4s ease-in-out;
  }
  mat-toolbar.app-toolbar aio-search-box.search-container input:focus {
    width: 80%;
  }
}
@media (max-width: 515px) {
  mat-toolbar.app-toolbar aio-search-box.search-container input {
    width: 150px;
  }
}
mat-toolbar.app-toolbar aio-search-box.search-container input::-webkit-search-cancel-button {
  display: none;
}
mat-toolbar.app-toolbar aio-search-box.search-container input::-ms-clear {
  display: none;
}
mat-toolbar.app-toolbar aio-search-box.search-container input:not(:focus):not(:hover) + mat-icon:not(:hover):not(:focus) {
  display: none;
}
mat-toolbar.app-toolbar aio-search-box.search-container .search-box-shortcut {
  display: none;
  position: absolute;
  right: 16px;
  font-size: 1.2rem;
  line-height: 2.2rem;
  padding: 0 0.8rem;
  pointer-events: none;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
  border: 1px solid;
}
mat-toolbar.app-toolbar aio-search-box.search-container input:not(:focus):placeholder-shown + .search-box-shortcut {
  display: block;
}
mat-toolbar.app-toolbar aio-search-box.search-container mat-icon {
  position: absolute;
  color: #1976D2;
  right: 0.7rem;
  font-size: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 350px) {
  mat-toolbar.app-toolbar aio-theme-toggle {
    padding-right: 2px;
    margin-right: 2px;
  }
}
@media screen and (max-width: 480px) {
  mat-toolbar.app-toolbar .toolbar-external-icons-container a {
    margin: 0 -8px 0 0;
  }
}
@media screen and (max-width: 420px) {
  mat-toolbar.app-toolbar .toolbar-external-icons-container a:not([title=GitHub]) {
    display: none;
  }
}
@media screen and (max-width: 350px) {
  mat-toolbar.app-toolbar .toolbar-external-icons-container a[title=GitHub] {
    display: none;
  }
}

/* ==============================
   LAYOUT STYLES
   ============================== */
.alert, .callout {
  padding: 16px;
  margin: 24px 0px;
  font-size: 14px;
  font-size: 1.4rem;
  width: 100%;
  box-sizing: border-box;
  clear: both;
}
.alert h1, .callout h1, .alert h2, .callout h2, .alert h3, .callout h3, .alert h4, .callout h4, .alert h5, .callout h5, .alert h6, .callout h6 {
  font-weight: 500;
}
.alert.archive-warning, .archive-warning.callout {
  border-radius: 4px;
  margin-bottom: 1rem;
}
.alert.archive-warning a, .archive-warning.callout a {
  font-weight: bold;
  text-decoration: underline;
}
.alert.archive-warning a:hover, .archive-warning.callout a:hover {
  opacity: 0.9;
}
.alert > p, .callout > p {
  margin: 8px 16px;
}

/* API LIST STYLES */
aio-api-list {
  /* LAYOUT */
}
aio-api-list .api-filter {
  display: flex;
  margin: 0 auto;
  /* API FILTER MENU */
  /* API SEARCH ICON */
}
@media (max-width: 600px) {
  aio-api-list .api-filter {
    flex-direction: column;
    margin: 16px auto;
  }
}
aio-api-list .api-filter aio-select {
  width: 200px;
}
@media screen and (max-width: 600px) {
  aio-api-list .api-filter aio-select {
    width: 100%;
  }
}
aio-api-list .api-filter .form-select-menu,
aio-api-list .api-filter .form-search {
  margin: 8px;
}
@media screen and (max-width: 600px) {
  aio-api-list .api-filter .form-select-menu,
aio-api-list .api-filter .form-search {
    margin-left: 0;
  }
}
aio-api-list .api-filter aio-select:first-child .form-select-menu {
  margin-left: 0;
}
aio-api-list .api-filter .form-search {
  position: relative;
  float: left;
}
aio-api-list .api-filter .form-search input {
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 32px;
  line-height: 3.2rem;
  outline: none;
  padding: 4px 16px 4px 32px;
  transition: all 0.2s;
  width: 182px;
}
aio-api-list .api-filter .form-search input:-ms-input-placeholder {
  font-size: 14px;
  font-size: 1.4rem;
}
aio-api-list .api-filter .form-search input::-ms-input-placeholder {
  font-size: 14px;
  font-size: 1.4rem;
}
aio-api-list .api-filter .form-search input::-webkit-input-placeholder {
  font-size: 14px;
  font-size: 1.4rem;
}
aio-api-list .api-filter .form-search input::placeholder {
  font-size: 14px;
  font-size: 1.4rem;
}
@media screen and (max-width: 600px) {
  aio-api-list .api-filter .form-search input {
    width: 100%;
  }
}
aio-api-list .api-filter .form-search .material-icons {
  font-size: 20px;
  font-size: 2rem;
  left: 8px;
  pointer-events: none;
  position: absolute;
  top: 12px;
  width: 20px;
  z-index: 1;
}
aio-api-list .api-filter .material-icons {
  right: 48px;
}
aio-api-list .api-list-container {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  position: relative;
  /* API CLASS LIST */
}
@media handheld and (max-width: 480px), screen and (max-device-width: 480px), screen and (max-width: 800px) {
  aio-api-list .api-list-container {
    padding: 24px 0 0;
  }
}
aio-api-list .api-list-container h2 {
  margin-top: 16px;
  margin-bottom: 16px;
}
aio-api-list .api-list-container .api-list {
  list-style: none;
  margin: 0 0 32px -8px;
  padding: 0;
  overflow: hidden;
}
@media screen and (max-width: 600px) {
  aio-api-list .api-list-container .api-list {
    margin: 0 0 0 -8px;
  }
}
aio-api-list .api-list-container .api-list li {
  font-size: 14px;
  font-size: 1.4rem;
  margin: 8px 0;
  line-height: 14px;
  line-height: 1.4rem;
  padding: 0;
  float: left;
  width: 33%;
  overflow: hidden;
  min-width: 330px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
aio-api-list .api-list-container .api-list li a {
  line-height: 16px;
  line-height: 1.6rem;
  padding: 0 16px;
  text-decoration: none;
  transition: all 0.3s;
}
aio-api-list .api-list-container .api-list li a.deprecated-api-item {
  text-decoration: line-through;
}

.api-body {
  max-width: 1200px;
}
.api-body h1 {
  margin-top: -4px;
}
.api-body details.overloads {
  box-shadow: none;
}
.api-body details.overloads > summary {
  justify-content: space-between;
  padding: 0;
}
.api-body details.overloads > summary h4 {
  margin: 0;
  clear: left;
}
.api-body details.overloads > .details-content {
  padding: 0;
  border-radius: 2px;
  box-shadow: none;
}
.api-body details.overloads > .details-content > *:not(hr) {
  margin: 16px 24px;
}
.api-body details.overload {
  box-shadow: none;
  margin: 0;
}
.api-body table {
  margin: 12px 0 24px;
}
.api-body table.item-table td {
  padding: 32px;
}
.api-body table.list-table td {
  padding: 16px 24px;
}
.api-body table.parameters-table {
  margin-top: 0;
  font-size: 14px;
  font-size: 1.4rem;
  box-shadow: none;
}
@media screen and (max-width: 480px) {
  .api-body table.parameters-table tr {
    display: flex;
    flex-direction: column;
  }
}
.api-body table.parameters-table tr td:first-child {
  font-weight: 600;
  padding-left: 16px;
  width: 20%;
}
.api-body table.parameters-table td {
  padding: 8px 8px 8px 0;
  border: 0;
  vertical-align: top;
}
.api-body table.property-table td {
  vertical-align: top;
}
.api-body table.method-table td > code, .api-body table.option-table td > code, .api-body table.list-table td > code {
  background-color: inherit;
  white-space: pre-wrap;
}
.api-body table.method-table .with-github-links, .api-body table.option-table .with-github-links, .api-body table.list-table .with-github-links {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.api-body table.method-table .with-github-links .github-links a .material-icons:hover, .api-body table.option-table .with-github-links .github-links a .material-icons:hover, .api-body table.list-table .with-github-links .github-links a .material-icons:hover {
  background: none;
}
.api-body table.method-table h3, .api-body table.option-table h3, .api-body table.list-table h3 {
  margin: 6px 0;
  font-weight: 500;
  clear: left;
}
.api-body table.method-table h4, .api-body table.option-table h4, .api-body table.list-table h4 {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 12px;
}
.api-body table th {
  text-transform: none;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}
.api-body table tr:last-child {
  border-bottom: none;
}
.api-body table td {
  vertical-align: middle;
}
@media screen and (max-width: 480px) {
  .api-body table tbody > tr > td tr td:first-child {
    background-color: inherit;
  }
}
.api-body table hr {
  margin: 16px 0;
}
.api-body table .short-description {
  margin-left: 0;
}
.api-body .class-overview {
  position: relative;
}
.api-body .class-overview code-example {
  clear: left;
}
.api-body .member-name .pln {
  font-weight: 700;
}
.api-body .short-description {
  margin-top: 8px;
}
.api-body .final-message {
  font-style: italic;
}
.api-body .api-heading {
  font-size: 14px;
  font-size: 1.4rem;
  margin: 16px;
}
.api-body .from-constructor,
.api-body .read-only-property,
.api-body .write-only-property {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  letter-spacing: 0.05rem;
  font-style: italic;
  border-radius: 4px;
  padding: 4px 6px;
}
.api-body .selector-list ul,
.api-body .inherited-members-list ul {
  padding: 0;
}
.api-body .selector-list ul li,
.api-body .inherited-members-list ul li {
  list-style: none;
  margin-bottom: 12px;
}
.api-body .selector-list li,
.api-body .selector-list a {
  font-weight: bold;
}
.api-body .selector-list li i,
.api-body .selector-list a i {
  font-weight: normal;
}

.github-links {
  float: right;
}
.github-links .material-icons {
  border-radius: 4px;
  padding: 4px;
  font-size: 20px;
  font-size: 2rem;
}

.breadcrumb-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.api-header {
  display: flex;
  align-items: center;
  margin-top: -4px;
}
@media screen and (max-width: 600px) {
  .api-header {
    flex-direction: column;
    align-items: flex-start;
  }
}
.api-header h1 {
  font-size: 24px;
  font-size: 2.4rem;
  margin: 0;
}

.deprecated-api-item {
  text-decoration: line-through;
}

/* API SYMBOLS */
/* SYMBOL CLASS */
.symbol {
  border-radius: 2px;
  display: inline-block;
  font-size: 10px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 16px;
  line-height: 1.6rem;
  margin-right: 8px;
  text-align: center;
  width: 16px;
}

/* Button Styles */
button {
  font-family: inherit;
}

a.button.mat-button,
.button {
  color: inherit;
  display: inline-block;
  line-height: 32px;
  line-height: 3.2rem;
  padding: 0px 16px;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 400;
  border-radius: 3px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  border: none;
}
a.button.mat-button.button-small,
.button.button-small {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 24px;
  line-height: 2.4rem;
  padding: 0px 8px;
}
a.button.mat-button.button-large,
.button.button-large {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 48px;
  line-height: 4.8rem;
  padding: 0px 24px;
}
a.button.mat-button.button-x-large,
.button.button-x-large {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 56px;
  line-height: 5.6rem;
  padding: 0px 24px;
}

.cta-bar {
  text-align: center;
}
.cta-bar .button {
  margin: 0px 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  transition: all 0.2s ease-in-out;
}
.cta-bar .button:hover {
  transform: scale(1.1);
}

.group-buttons {
  margin: 16px auto 32px;
}
.group-buttons button.button.mat-button.filter-button {
  border-radius: 4px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 48px;
  line-height: 4.8rem;
  margin: 8px;
  padding: 0px 16px;
  width: 16.8rem;
  min-width: max-content;
}
@media (max-width: 480px) {
  .group-buttons button.button.mat-button.filter-button {
    font-size: 14px;
    font-size: 1.4rem;
    width: auto;
  }
}

[mat-button], [mat-raised-button], [mat-button], [mat-raised-button] {
  text-transform: uppercase;
}

.callout {
  padding: 0px;
  border-left: none !important;
  border-radius: 4px;
}
.callout header {
  line-height: 24px;
  line-height: 2.4rem;
  font-weight: 500;
  padding: 8px 16px;
  margin: 0;
  text-transform: uppercase;
  border-radius: 4px 4px 0 0;
}
.callout p {
  padding: 16px;
  margin: 0px;
  font-size: 14px;
  font-size: 1.4rem;
}
.callout > *:not(:first-child) {
  padding: 16px 24px;
}

.card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 16px 0;
}
.card-container .docs-card {
  width: 35%;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  box-sizing: border-box;
  transition: box-shadow 0.5s;
  max-width: 340px;
  min-width: 300px;
  margin: 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-container .docs-card:hover {
  box-shadow: 0 8px 8px rgba(10, 16, 20, 0.24), 0 0 8px rgba(10, 16, 20, 0.12);
  text-decoration: none;
}
@media screen and (max-width: 600px) {
  .card-container .docs-card {
    width: 100%;
    margin: 8px auto;
    max-width: none;
  }
}
.card-container .docs-card section {
  font-size: 20px;
  font-size: 2rem;
  line-height: 24px;
  line-height: 2.4rem;
  margin: 0;
  padding: 2.7rem 1.5rem 2.1rem;
  text-transform: none;
  text-align: center;
}
.card-container .docs-card p {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 24px;
  line-height: 2.4rem;
  padding: 0 16px;
  margin: 0;
  margin-bottom: 1.6rem;
  text-align: center;
}
.card-container .docs-card .card-footer {
  margin-bottom: 0;
  box-sizing: border-box;
  line-height: 24px;
  line-height: 2.4rem;
  padding: 1.3rem 1.5rem;
  text-align: right;
}
.card-container .docs-card .card-footer a {
  font-size: 13px;
  font-size: 1.3rem;
}

.card-section {
  padding: 16px 32px;
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card-section:hover {
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
}
.card-section h1,
.card-section h2,
.card-section h3,
.card-section h4,
.card-section h5,
.card-section h6 {
  margin: 8px 0;
}
.card-section a,
.card-section .button,
.card-section button {
  text-align: center;
}

.cli-name {
  font-weight: bold;
  /* override code format */
}
.cli-name .kwd {
  color: inherit;
}

.cli-option-syntax {
  white-space: pre;
}

code-example,
code-tabs {
  clear: both;
  display: block;
}
code-example code,
code-tabs code {
  overflow: auto;
}
code-example ol,
code-tabs ol {
  list-style: decimal;
}
code-example .mat-card,
code-tabs .mat-card {
  padding: 0;
  border-radius: 5px;
}

code-example:not(.no-box) {
  border-radius: 5px;
  margin: 16px auto;
}
code-example.no-box pre.prettyprint {
  margin: 0;
}
code-example.no-box code {
  background-color: transparent;
}
code-example code {
  overflow: auto;
}
code-example header {
  border-radius: 5px 5px 0 0;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 8px 16px;
}

code-tabs {
  margin: 16px 0;
}
code-tabs .code-tab-group .mat-tab-body {
  overflow-y: hidden;
}
code-tabs .code-tab-group .mat-tab-body .mat-tab-body-content {
  height: auto;
  transform: none;
}
code-tabs .code-tab-group .mat-tab-body .mat-tab-body-content .fadeIn {
  animation: opacity 2s ease-in;
}

aio-code pre.prettyprint {
  position: relative;
  display: flex;
  min-height: 32px;
  margin: 16px 24px;
  white-space: pre-wrap;
  align-items: center;
}
aio-code pre.prettyprint code a {
  color: inherit;
}
aio-code pre.prettyprint code span {
  line-height: 24px;
  line-height: 2.4rem;
}
aio-code pre.prettyprint code ol.linenums {
  margin: 0;
}
aio-code pre.prettyprint code ol.linenums li {
  margin: 0;
  font-family: "Roboto Mono", monospace;
  font-size: 90%;
  line-height: 24px;
  line-height: 2.4rem;
}
aio-code pre.prettyprint .copy-button {
  position: absolute;
  top: -7px;
  right: -19px;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
aio-code pre.prettyprint .copy-button:hover, aio-code pre.prettyprint .copy-button:focus {
  transform: scale(1.1);
}
aio-code pre.prettyprint .copy-button:active {
  transform: translateY(2px) scale(1.05);
}

.sidenav-content code a {
  font-size: inherit;
  font-weight: inherit;
}
.sidenav-content :not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(pre) > code {
  border-radius: 4px;
  padding: 4px;
}
.sidenav-content :not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(pre):is(a) > code {
  line-height: 24px;
  line-height: 2.4rem;
}
.page-guide-cheatsheet .sidenav-content td:first-of-type code,
.page-guide-cheatsheet .sidenav-content th code {
  background-color: inherit;
  padding: 0;
  white-space: pre-wrap;
}
.sidenav-content .code-anchor {
  cursor: pointer;
  font-size: inherit;
}
.sidenav-content .code-anchor:hover {
  text-decoration: underline;
}

.contribute-container h2 {
  margin: 0;
}
.contribute-container .card-section {
  justify-content: space-between;
}
@media (max-width: 600px) {
  .contribute-container .card-section {
    flex-direction: column;
  }
}
.contribute-container .card-section > :first-child {
  margin-right: 2rem;
  width: 67%;
}
@media (max-width: 600px) {
  .contribute-container .card-section > :first-child {
    width: 100%;
  }
}
.contribute-container .card-section:last-child {
  margin-bottom: 0;
}
@media (max-width: 600px) {
  .contribute-container .card-section .button {
    margin-top: 14px;
  }
}

aio-cookies-popup .cookies-popup {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  bottom: 0;
  left: 0;
  position: fixed;
  margin: 24px;
  max-width: 430px;
  padding: 16px 16px 8px;
  z-index: 1001;
}
aio-cookies-popup .cookies-popup .actions {
  display: flex;
  justify-content: flex-end;
  margin: 16px -8px 0 0;
}
aio-cookies-popup .cookies-popup .actions .mat-button {
  text-transform: uppercase;
}

aio-contributor-list .contributor-group {
  --columns: 2;
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  justify-items: stretch;
}
@media (max-width: 75rem) {
  aio-contributor-list .contributor-group {
    --columns: 1;
  }
}

aio-contributor {
  display: grid;
}
aio-contributor .contributor-card {
  margin: 3.5rem;
  display: grid;
  grid-template-columns: 10rem 1fr;
  grid-template-rows: min-content 1fr min-content;
  padding: 3rem 3rem 0 3rem;
  border-radius: 5px;
}
aio-contributor .contributor-card.no-image {
  grid-template-columns: 0 1fr;
}
aio-contributor .contributor-card .contributor-image-wrapper {
  grid-column: 1/2;
  grid-row: 1/5;
  width: 14rem;
  height: 14rem;
  position: relative;
  top: -5rem;
  left: -5rem;
  clip-path: polygon(50% 0, 99% 18%, 89% 78%, 50% 100%, 11% 78%, 3% 17%);
}
aio-contributor .contributor-card .contributor-image {
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  clip-path: polygon(50% 0, 99% 18%, 89% 78%, 50% 100%, 11% 78%, 3% 17%);
  object-fit: cover;
}
aio-contributor .contributor-card .contributor-social-links {
  display: flex;
  grid-column: 1;
  grid-row: 2;
  align-self: end;
  margin-bottom: 0.5rem;
}
aio-contributor .contributor-card .contributor-social-links a {
  display: inline-block;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  overflow: hidden;
}
aio-contributor .contributor-card .contributor-social-links a mat-icon {
  font-size: 3rem;
  height: 3rem;
  width: 3rem;
}
aio-contributor .contributor-card .contributor-social-links a mat-icon svg {
  height: 3rem;
  width: 3rem;
}
aio-contributor .contributor-card .contributor-social-links a mat-icon.link-icon {
  transform: rotate(45deg);
}
aio-contributor .contributor-card .contributor-title {
  margin-top: 0;
  grid-column: 2;
}
aio-contributor .contributor-card .contributor-bio {
  grid-column: 2;
  margin-bottom: 2rem;
}
@media (max-width: 45rem) {
  aio-contributor .contributor-card {
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
  }
  aio-contributor .contributor-card.no-image {
    grid-template-columns: 1fr;
  }
  aio-contributor .contributor-card .contributor-image-wrapper {
    grid-column: 1;
    grid-row: 1;
    left: 0;
    justify-self: center;
    margin-bottom: -3rem;
  }
  aio-contributor .contributor-card .contributor-title {
    text-align: center;
  }
  aio-contributor .contributor-card .contributor-social-links {
    grid-row: 4;
    justify-content: space-evenly;
  }
  aio-contributor .contributor-card .contributor-social-links,
aio-contributor .contributor-card .contributor-title,
aio-contributor .contributor-card .contributor-bio {
    grid-column: 1;
  }
}

/*
 * General styling to make detail/summary tags look a bit more material
 * To get the best out of it you should structure your usage like this:
 *
 * ```html
 * <details>
 *   <summary>Some title</summary>
 *   <div class="details-content">
 *     Some content
 *   </div>
 * </details>
 * ```
 *
 * Optionally, you can use an `.actions` container inside `<summary>` to show expand/collapse
 * actions and/or a rotating icon:
 * ```html
 * <summary>
 *   <span>Some title</span>
 *   <span class="actions">
 *     <span class="action-expand">Show more</span>
 *     <span class="action-collapse">Show less</span>
 *     <i class="material-icons expand">expand_more</i>
 *   </span>
 * </summary>
 * ```
 */
details > summary {
  cursor: pointer;
  font-size: 16px;
  font-size: 1.6rem;
  position: relative;
  padding: 16px;
  height: inherit;
  display: flex;
  align-items: center;
  overflow: hidden;
}
details > summary::-webkit-details-marker {
  display: none;
}
details > summary > h2 {
  margin: 0;
  padding: 0;
  border: none;
  display: inline;
}
details > summary .actions {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-size: 1.4rem;
}
details > summary .actions .action-expand {
  display: initial;
}
details[open] > summary .actions .action-expand {
  display: none;
}

details > summary .actions .action-collapse {
  display: none;
}
details[open] > summary .actions .action-collapse {
  display: initial;
}

details > summary .actions .material-icons.expand {
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}
details[open] > summary .actions .material-icons.expand {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}

details .details-content {
  padding: 16px 24px;
}

.error-list {
  display: grid;
  list-style: none;
  padding: 0;
  overflow: hidden;
}
@media screen and (max-width: 600px) {
  .error-list {
    margin: 0 0 0 -8px;
  }
}
.error-list li {
  font-size: 14px;
  font-size: 1.4rem;
  margin: 8px 0;
  line-height: 14px;
  line-height: 1.4rem;
  padding: 0;
  float: left;
  overflow: hidden;
  min-width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.error-list li .symbol.runtime:before {
  content: "R";
}
.error-list li .symbol.compiler:before {
  content: "C";
}
.error-list li a {
  display: inline-block;
  line-height: 16px;
  line-height: 1.6rem;
  padding: 0 16px 0;
  text-decoration: none;
  transition: all 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
}

.feature-section {
  margin: 0 0 32px;
}
.feature-section .feature-header, .feature-section .text-headline {
  text-align: center;
}
.feature-section .feature-header img {
  margin: 16px;
  max-width: 70px;
}
.feature-section .feature-title {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 8px 0px;
  clear: both;
}
.feature-section .feature-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
@media (max-width: 1057px) {
  .feature-section .feature-row {
    flex-direction: column;
  }
}
.feature-section .feature-row .feature {
  max-width: 300px;
  margin: 0 16px;
}
@media (max-width: 1057px) {
  .feature-section .feature-row .feature {
    max-width: 100%;
    padding: 8px 10%;
  }
}
@media (max-width: 768px) {
  .feature-section .feature-row .feature {
    padding: 8px 0;
  }
}

.filetree {
  border-radius: 4px;
  margin: 0 0 24px 0;
  padding: 16px 32px;
}
.filetree .file {
  display: block;
  letter-spacing: 0.3px;
  letter-spacing: 0.03rem;
  line-height: 32px;
  line-height: 3.2rem;
}
.filetree .children {
  padding-left: 24px;
  position: relative;
  overflow: hidden;
}
.filetree .children .file {
  position: relative;
}
.filetree .children .file:before {
  content: "";
  left: -18px;
  bottom: 16px;
  width: 16px;
  height: 9999px;
  position: absolute;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-radius: 0 0 0 3px;
}

.reviewed {
  font-size: 13px;
  font-size: 1.3rem;
  font-style: italic;
  text-align: right;
}

.sidenav-content h1 .header-link, .sidenav-content h2 .header-link, .sidenav-content h3 .header-link, .sidenav-content h4 .header-link, .sidenav-content h5 .header-link, .sidenav-content h6 .header-link {
  margin: 0 6px;
  text-decoration: none;
  user-select: none;
  visibility: hidden;
  display: inline-flex;
  vertical-align: middle;
}
.sidenav-content h1:hover .header-link, .sidenav-content h2:hover .header-link, .sidenav-content h3:hover .header-link, .sidenav-content h4:hover .header-link, .sidenav-content h5:hover .header-link, .sidenav-content h6:hover .header-link {
  visibility: visible;
}

hr {
  border: none;
  height: 1px;
}

.content img.right {
  clear: both;
  float: right;
  margin-left: 20px;
  margin-bottom: 20px;
}
.content img.left {
  clear: both;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}
@media (max-width: 1300px) {
  .content img {
    max-width: 100%;
    height: auto;
    margin: auto;
  }
}
@media (max-width: 600px) {
  .content img {
    float: none !important;
  }
  .content img.right {
    margin-left: 0;
  }
  .content img.left {
    margin-right: 0;
  }
}
.content .lightbox {
  margin: 0;
  margin-top: 14px;
  margin-bottom: 14px;
  border-radius: 1px;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
  margin: 16px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.content .lightbox img {
  max-width: 100%;
  height: auto;
  padding: 8px;
  margin: auto;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  border-radius: 4px;
}

.api-header label,
label.api-status-label {
  border-radius: 4px;
  padding: 2px 10px;
  display: inline;
  font-size: 12px;
  font-size: 1.2rem;
  margin-right: 8px;
  font-weight: 500;
  text-transform: uppercase;
}
@media screen and (max-width: 600px) {
  .api-header label,
label.api-status-label {
    display: block;
    margin: 4px 0;
  }
}
.api-header label.page-label,
label.api-status-label.page-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  width: 140px;
}
.api-header label.page-label .material-icons,
label.api-status-label.page-label .material-icons {
  margin-right: 8px;
}
.api-header label.property-type-label,
label.api-status-label.property-type-label {
  font-size: 12px;
  font-size: 1.2rem;
  text-transform: none;
}

.app-toolbar mat-toolbar-row.notification-container {
  padding: 0;
  height: auto;
  overflow: hidden;
}

aio-notification {
  display: flex;
  position: relative;
  width: 100%;
  height: 56px;
  justify-content: center;
}
@media (max-width: 430px) {
  aio-notification {
    justify-content: flex-start;
    padding-left: 10px;
  }
}
aio-notification .close-button {
  width: 56px;
  height: 56px;
}
aio-notification .content {
  width: calc(100% - 56px);
  text-transform: none;
  padding: 0;
}
aio-notification .content > * {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
aio-notification .content .title {
  margin-right: 10px;
}
aio-notification .content .link {
  text-transform: none;
}
aio-notification .content .icon {
  margin-right: 10px;
}
@media (max-width: 464px) {
  aio-notification .content .icon {
    display: none;
  }
}
aio-notification .content .message {
  overflow: auto;
  margin-right: 10px;
  color: #bae0ff;
}
aio-notification .content .action-button {
  border-radius: 15px;
  text-transform: uppercase;
  padding: 0 10px;
  font-size: 12px;
  font-size: 1.2rem;
}
@media (max-width: 780px) {
  aio-notification .content .action-button {
    display: none;
  }
}

.aio-notification-show .toc-container {
  top: 132px;
}
.aio-notification-show .search-results {
  border-top-width: 124px;
}
.aio-notification-show mat-sidenav-container.sidenav-container .sidenav-content {
  padding-top: 136px;
}
.aio-notification-show mat-sidenav-container.sidenav-container mat-sidenav.sidenav {
  top: 120px;
}
@media (max-width: 600px) {
  .aio-notification-show mat-sidenav-container.sidenav-container mat-sidenav.sidenav {
    top: 112px;
  }
}

.page-about.aio-notification-show mat-sidenav-container.sidenav-container .sidenav-content, .page-contribute.aio-notification-show mat-sidenav-container.sidenav-container .sidenav-content, .page-events.aio-notification-show mat-sidenav-container.sidenav-container .sidenav-content, .page-features.aio-notification-show mat-sidenav-container.sidenav-container .sidenav-content, .page-home.aio-notification-show mat-sidenav-container.sidenav-container .sidenav-content, .page-presskit.aio-notification-show mat-sidenav-container.sidenav-container .sidenav-content, .page-resources.aio-notification-show mat-sidenav-container.sidenav-container .sidenav-content {
  padding-top: 56px;
}

.aio-notification-animating .sidenav-content {
  transition: padding-top 250ms ease;
}
.aio-notification-animating mat-sidenav.sidenav, .aio-notification-animating .toc-container {
  transition: top 250ms ease;
}

.progress-bar-container {
  height: 2px;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 11;
}

.presskit-container {
  padding: 0 32px 32px 32px;
}
.presskit-container img {
  height: 128px;
  width: auto;
}
.presskit-container .presskit-section:not(:first-child) {
  margin-top: 4rem;
  padding-top: 2rem;
}
.presskit-container .presskit-section .presskit-icon-group {
  display: flex;
  flex-wrap: wrap;
}
.presskit-container .presskit-section .presskit-icon-group .presskit-icon-item {
  align-items: center;
  display: flex;
  margin: 1rem;
  width: calc(50% - 2rem);
}
@media screen and (max-width: 600px) {
  .presskit-container .presskit-section .presskit-icon-group .presskit-icon-item {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 2rem;
  }
}
.presskit-container .presskit-section .presskit-icon-group .presskit-icon-item .presskit-image-container {
  flex: none;
  margin-right: 2rem;
}
@media (max-width: 600px) {
  .presskit-container .presskit-section .presskit-icon-group .presskit-icon-item .presskit-image-container {
    width: 100%;
    margin-right: 0;
  }
}
.presskit-container .presskit-section .presskit-icon-group .presskit-icon-item .presskit-image-container .transparent-img, .presskit-container .presskit-section .presskit-icon-group .presskit-icon-item .presskit-image-container .transparent-img-inverse {
  border-radius: 50%;
}
.presskit-container .presskit-section .presskit-icon-group .presskit-icon-item .presskit-links-container {
  list-style-type: none;
  margin-bottom: 0;
  padding: 0;
}
.presskit-container .presskit-section .presskit-icon-group .presskit-icon-item .presskit-links-container a {
  padding-right: 3rem;
  position: relative;
}
.presskit-container .presskit-section .presskit-icon-group .presskit-icon-item .presskit-links-container a::after {
  content: "cloud_download";
  font-family: "Material Icons";
  font-size: 24px;
  font-size: 2.4rem;
  position: absolute;
  right: 0;
}

aio-resource-list .showcase {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 1px 4px 0 rgba(10, 16, 20, 0.37);
  border-radius: 4px;
  margin-bottom: 48px;
}
aio-resource-list .resource-item .resource-name {
  margin: 0;
  line-height: 24px;
  line-height: 2.4rem;
  font-size: 20px;
  font-size: 2rem;
}
aio-resource-list .resource-item .resource-description {
  margin: 0;
}
aio-resource-list .subcategory-title {
  padding: 16px 23px;
  margin: 0;
  font-size: 24px;
  font-size: 2.4rem;
}
aio-resource-list .resource-row-link {
  display: flex;
  flex-direction: column;
  border: transparent solid 1px;
  padding: 16px 23px 16px 23px;
  transition: all 0.3s;
}
aio-resource-list .resource-row-link:hover {
  text-decoration: none;
  border-radius: 4px;
  transform: translateY(-2px);
}

.page-guide-roadmap .completed-details {
  box-shadow: none;
  margin-top: 4rem;
  padding: 4rem 0 2rem;
}
.page-guide-roadmap .completed-details > summary {
  justify-content: space-between;
  padding: 0;
}
.page-guide-roadmap .completed-details > .details-content {
  padding: 0;
}
.page-guide-roadmap .roadmap-last-updated {
  font-style: italic;
}

aio-search-results {
  z-index: 10;
}
aio-search-results .search-results {
  display: flex;
  flex-direction: row;
  overflow: auto;
  padding: 0px 32px;
  border-top: 68px solid transparent;
  width: auto;
  max-height: 95vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  box-sizing: border-box;
}
aio-search-results .search-results .search-area {
  margin: 0 auto;
  padding: 16px;
}
aio-search-results .search-results .search-area .search-section-header {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 400;
  margin: 10px 0px 5px;
  text-transform: uppercase;
}
aio-search-results .search-results .search-area ul {
  margin: 0;
  padding: 0;
}
aio-search-results .search-results .search-area ul li {
  list-style: none;
}
aio-search-results .search-results .search-area ul .search-result-item {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 24px;
  line-height: 2.4rem;
  display: inline-block;
  font-weight: normal;
  padding: 0.6rem 0;
}
aio-search-results .search-results .search-area ul .search-result-itema {
  text-decoration: none;
}
aio-search-results .search-results .search-area ul.priority-pages {
  padding: 0.5rem 0;
}
aio-search-results .search-results .search-area ul.priority-pages .search-result-item {
  font-weight: bold;
}
aio-search-results .search-results .no-results {
  text-align: center;
  margin: 16px;
}
aio-search-results .search-results a {
  font-weight: 500;
}
@media (max-width: 600px) {
  aio-search-results .search-results {
    display: block;
  }
}
aio-search-results .search-results .close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  padding: 0;
  margin: 3px;
  display: flex;
  opacity: 0;
  width: 0;
  background-color: inherit;
  cursor: pointer;
  transition: 100ms opacity;
}
aio-search-results .search-results .close-button:focus {
  opacity: 1;
  width: auto;
}
aio-search-results.embedded .search-results {
  padding: 0;
  color: inherit;
  max-height: 100%;
  position: relative;
  background-color: inherit;
  box-shadow: none;
}

/* SELECT MENU */
aio-select .form-select-menu {
  position: relative;
}
aio-select .form-select-button {
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 32px;
  line-height: 3.2rem;
  outline: none;
  padding: 4px 16px;
  text-align: left;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
}
aio-select .form-select-button strong {
  font-weight: 600;
  margin-right: 8px;
  text-transform: capitalize;
}
aio-select .form-select-button[disabled] {
  cursor: not-allowed;
}
aio-select .form-select-dropdown {
  border-radius: 4px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
aio-select .form-select-dropdown li {
  cursor: pointer;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 32px;
  line-height: 3.2rem;
  margin: 0;
  padding: 4px 16px 4px 40px;
  position: relative;
  transition: all 0.2s;
  border: 1px solid transparent;
}
aio-select .form-select-dropdown li:first-child {
  border-radius: 4px 4px 0 0;
}
aio-select .form-select-dropdown li:last-child {
  border-radius: 0 0 4px 4px;
}
aio-select .form-select-dropdown li .symbol {
  left: 16px;
  position: absolute;
  top: 12px;
  z-index: 5;
}

.skip-to-content-link {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  background: #fafafa;
  border: 1px dashed;
  border-radius: 8px;
  color: #1976D2;
  font-weight: 500;
  margin: 5px;
  outline: none;
  padding: 1rem 1.5rem;
  position: fixed;
  top: -100%;
  z-index: 1001;
}
.skip-to-content-link:active, .skip-to-content-link:focus {
  top: auto;
}
.skip-to-content-link:hover {
  text-decoration: underline;
}

table {
  margin: 24px 0px;
  border-radius: 2px;
}
table.is-full-width {
  width: 100%;
}
table.is-fixed-layout {
  table-layout: fixed;
}
table thead {
  vertical-align: middle;
  border-color: inherit;
}
table thead > tr {
  vertical-align: inherit;
  border-color: inherit;
}
table thead > tr > th {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 8px 24px;
  text-align: left;
  text-transform: uppercase;
  line-height: 28px;
  line-height: 2.8rem;
}
table tbody > tr > th,
table tbody > tr > td {
  padding: 16px;
  text-align: left;
  line-height: 24px;
  line-height: 2.4rem;
  vertical-align: baseline;
}
@media (max-width: 480px) {
  table tbody > tr > th:before,
table tbody > tr > td:before {
    display: inline-block;
  }
}
table tbody > tr > td {
  letter-spacing: 0.3px;
  letter-spacing: 0.03rem;
}
table tbody > tr > th {
  font-weight: 600;
  max-width: 100px;
}
table tbody > tr:last-child td {
  border: none;
}

.page-guide-cheatsheet table tbody td {
  overflow: auto;
}
@media only screen and (max-width: 990px) {
  .page-guide-cheatsheet {
    /* Force table to not be like tables anymore */
  }
  .page-guide-cheatsheet table, .page-guide-cheatsheet thead, .page-guide-cheatsheet tbody, .page-guide-cheatsheet tfoot, .page-guide-cheatsheet tr, .page-guide-cheatsheet th, .page-guide-cheatsheet td {
    display: block;
    position: relative;
    max-width: 100%;
  }
  .page-guide-cheatsheet th {
    border-right: none;
  }
  .page-guide-cheatsheet th:not(:last-child), .page-guide-cheatsheet td:not(:last-child) {
    border-bottom: none;
    padding-bottom: 0px;
  }
}

.page-events tr > td, .page-events tr > th {
  width: 33%;
}

.toc-container {
  width: 18vw;
  position: fixed;
  top: 76px;
  right: 0;
  bottom: 12px;
  overflow-y: auto;
  overflow-x: hidden;
}

aio-toc .toc-inner {
  font-size: 13px;
  font-size: 1.3rem;
  overflow-y: visible;
  padding: 4px 0 0 10px;
}
aio-toc .toc-inner .toc-heading,
aio-toc .toc-inner .toc-list .h1 {
  font-size: 16px;
  font-size: 1.6rem;
}
aio-toc .toc-inner .toc-heading {
  font-weight: 500;
  margin: 0 0 16px 8px;
  padding: 0;
}
aio-toc .toc-inner .toc-heading.secondary {
  position: relative;
  top: -8px;
}
aio-toc .toc-inner button.toc-heading, aio-toc .toc-inner button.toc-more-items {
  cursor: pointer;
  display: inline-block;
  background: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  text-align: start;
}
aio-toc .toc-inner button.toc-heading.embedded:focus, aio-toc .toc-inner button.toc-more-items.embedded:focus {
  outline: none;
}
aio-toc .toc-inner button.toc-heading mat-icon.rotating-icon {
  height: 18px;
  width: 18px;
  position: relative;
  left: -4px;
  top: 5px;
}
aio-toc .toc-inner button.toc-more-items {
  top: 10px;
  position: relative;
}
aio-toc .toc-inner button.toc-more-items::after {
  content: "expand_less";
}
aio-toc .toc-inner button.toc-more-items.collapsed::after {
  content: "more_horiz";
}
aio-toc .toc-inner .mat-icon.collapsed {
  -moz-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}
aio-toc .toc-inner .mat-icon:not(.collapsed) {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}
aio-toc .toc-inner ul.toc-list {
  list-style-type: none;
  margin: 0;
  padding: 0 8px 0 0;
}
@media (max-width: 800px) {
  aio-toc .toc-inner ul.toc-list {
    width: auto;
  }
}
aio-toc .toc-inner ul.toc-list li {
  box-sizing: border-box;
  line-height: 24px;
  line-height: 2.4rem;
  padding: 9px 0 9px 12px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
aio-toc .toc-inner ul.toc-list li.h1:after {
  content: "";
  display: block;
  height: 1px;
  width: 40%;
  margin: 7px 0 4px 0;
  clear: both;
}
aio-toc .toc-inner ul.toc-list li.h3 {
  padding-left: 24px;
}
aio-toc .toc-inner ul.toc-list li a {
  overflow: visible;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: inherit;
  display: table-cell;
}
aio-toc .toc-inner ul.toc-list li.active * {
  font-weight: 500;
}
aio-toc .toc-inner ul.toc-list li.active a:before {
  border-radius: 50%;
  content: "";
  height: 6px;
  left: -2.5px;
  position: absolute;
  top: calc(9px + 12px - 3px);
  top: calc(9px + 1.2rem - 3px);
  width: 6px;
}
aio-toc .toc-inner ul.toc-list:not(.embedded) li:before {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  border-left-width: 1px;
  border-left-style: solid;
}
aio-toc .toc-inner ul.toc-list:not(.embedded) li:first-child:before {
  top: calc(9px + 12px - 3px);
  top: calc(9px + 1.2rem - 3px);
}
aio-toc .toc-inner ul.toc-list:not(.embedded) li:last-child:before {
  bottom: calc(100% - (9px + 12px + 3px));
  bottom: calc(100% - (9px + 1.2rem + 3px));
}
aio-toc .toc-inner ul.toc-list:not(.embedded) li:not(.active):hover a:before {
  border-radius: 50%;
  content: "";
  height: 6px;
  left: -2.5px;
  position: absolute;
  top: calc(9px + 12px - 3px);
  top: calc(9px + 1.2rem - 3px);
  width: 6px;
}
@media (min-width: 801px) {
  aio-toc.embedded {
    display: none;
  }
}
aio-toc.embedded .toc-inner {
  padding: 12px 0 0 0;
}
aio-toc.embedded .toc-inner .toc-heading {
  margin: 0 0 8px;
}
aio-toc.embedded .toc-inner.collapsed .secondary {
  display: none;
}

/* ==============================
   MODULE STYLES
   ============================== */
@media print {
  * {
    box-shadow: none !important;
  }
  body,
mat-sidenav-container {
    background: none !important;
  }
  h1 {
    height: 40px !important;
    color: #444444 !important;
  }
  h1,
h2,
h3,
h4,
h5,
h6 {
    page-break-after: avoid;
  }
  ul,
ol,
img,
code-example,
table,
tr,
.alert,
.feature,
.lightbox {
    page-break-inside: avoid;
  }
  table tbody tr:last-child td {
    border-bottom: 1px solid #DBDBDB !important;
  }
  img {
    max-width: 100% !important;
  }
  p {
    widows: 4;
  }
  p > code,
li > code,
table code {
    color: #1976D2 !important;
  }
  .no-print {
    display: none !important;
  }
  mat-sidenav.sidenav.mat-sidenav {
    display: none !important;
  }
  .mat-sidenav-content {
    margin: 0 !important;
  }
  mat-sidenav-container.sidenav-container {
    min-width: 100vw;
  }
  .sidenav-content {
    overflow: visible;
  }
  section#intro .hero-logo {
    align-items: center;
    background: none;
    justify-content: center;
  }
  section#intro .hero-logo::after {
    font-size: 64px;
    font-size: 6.4rem;
    line-height: 100px;
    line-height: 10rem;
    color: #DD0031;
    content: "Angular";
    font-weight: bold;
  }
  .filetree {
    max-width: 100%;
  }
  aio-code code {
    border: none !important;
  }
  code-example[language=shell], code-example[language=sh], code-example[language=bash] {
    background: none;
  }
  code-example[language=shell] .pnk,
code-example[language=shell] .blk,
code-example[language=shell] .pln,
code-example[language=shell] .otl,
code-example[language=shell] .kwd,
code-example[language=shell] .typ,
code-example[language=shell] .tag,
code-example[language=shell] .str,
code-example[language=shell] .atv,
code-example[language=shell] .atn,
code-example[language=shell] .com,
code-example[language=shell] .lit,
code-example[language=shell] .pun,
code-example[language=shell] .dec, code-example[language=sh] .pnk,
code-example[language=sh] .blk,
code-example[language=sh] .pln,
code-example[language=sh] .otl,
code-example[language=sh] .kwd,
code-example[language=sh] .typ,
code-example[language=sh] .tag,
code-example[language=sh] .str,
code-example[language=sh] .atv,
code-example[language=sh] .atn,
code-example[language=sh] .com,
code-example[language=sh] .lit,
code-example[language=sh] .pun,
code-example[language=sh] .dec, code-example[language=bash] .pnk,
code-example[language=bash] .blk,
code-example[language=bash] .pln,
code-example[language=bash] .otl,
code-example[language=bash] .kwd,
code-example[language=bash] .typ,
code-example[language=bash] .tag,
code-example[language=bash] .str,
code-example[language=bash] .atv,
code-example[language=bash] .atn,
code-example[language=bash] .com,
code-example[language=bash] .lit,
code-example[language=bash] .pun,
code-example[language=bash] .dec {
    color: #444444;
  }
  code-example header {
    background: none;
    border: 0.5px solid #DBDBDB;
    color: #444444;
  }
  .content code {
    border: 0.5px solid #DBDBDB;
  }
  .mat-tab-labels div.mat-tab-label:not(.mat-tab-label-active) span {
    font-style: italic;
  }
  .mat-tab-labels div.mat-tab-label.mat-tab-label-active span {
    font-weight: bold;
  }
  .api-header label {
    color: #444444 !important;
    font-weight: bold !important;
    margin: 2px !important;
    padding: 0 !important;
    display: block !important;
  }
  .feature-section img {
    max-width: 70px !important;
  }
}
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}